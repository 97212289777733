<template>
    <el-container class="chat-page">
        <el-aside class="chat-list-box">
            <el-scrollbar class="chat-list-items">
                <div v-for="(chat, index) in chatStore.chats" :key="index">
                    <chat-item v-show="chat.showName.startsWith(searchText)" :chat="chat" :index="index"
                        @click.native="onActiveItem(index)" @delete="onDelItem(index)" @top="onTop(index)"
                        :active="chat === chatStore.activeChat"></chat-item>
                </div>
            </el-scrollbar>
        </el-aside>
    </el-container>
</template>

<script>
import ChatItem from "../components/chat/ChatItem.vue";
export default {
    name: "chat",
    components: {
        ChatItem,
    },
    data () {
        return {
            searchText: "",
        }
    },
    methods: {
        onActiveItem (index) {
            this.$store.commit("activeChat", index);
            this.$router.push("/home/chatBox");
        },
        onDelItem (index) {
            this.$store.commit("removeChat", index);
        },
        onTop (chatIdx) {
            this.$store.commit("moveTop", chatIdx);
        },
    },
    computed: {
        chatStore () {
            // this.onActiveItem(0);
            console.log('this.$store.state.chatStore', this.$store.state.chatStore);
            return this.$store.state.chatStore;
        },
        loading () {
            return this.chatStore.loadingGroupMsg || this.chatStore.loadingPrivateMsg
        }
    }
}
</script>

<style lang="scss">
.chat-page {
    .chat-list-box {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        border: #dddddd solid 1px;
        background: white;

        .chat-list-header {
            padding: 3px 8px;
            line-height: 50px;
            border-bottom: 1px #ddd solid;

            .el-input__inner {
                border-radius: 10px !important;
                background-color: #F8F8F8;
            }

        }

        .chat-list-loadding {
            height: 50px;
            background-color: #eee;

            .chat-loading-box {
                height: 100%;
            }
        }

        .chat-list-items {
            flex: 1;
            background: #F8F8F8;
            margin: 0 3px;
        }
    }
}
</style>